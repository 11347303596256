import { Button, Input } from 'antd';
import React, { useState } from 'react';
import axios from "axios";

// const writeUrl = "http://localhost:8081/write";
const writeUrl = "https://minecraft.teststudio.xyz/write";


const ModalDialog = (props: { key: string, name: string, value: string, children: React.ReactNode }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState(props.name);
    const [value, setValue] = useState(props.value);

    const openModal = () => {
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };
    const stopPropagation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    };
    const editButton = async () => {
        const result = window.confirm("編集して良いですか？");
        if (result) {
            const res = await axios.post(writeUrl, JSON.stringify({
                data: {
                    name: name,
                    value: value
                }
            }),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            );
            closeModal();
            window.location.reload();
        }
    }

    return (
        <>
            {!isOpen && (
                <div onClick={() => {
                    if (props.name === '') {
                        openModal();
                    }
                }}>
                    {props.children}
                </div>

            )}
            {isOpen && (
                <>
                    {props.children}
                    <div style={styles.modal} onClick={closeModal}>
                        <div onClick={stopPropagation} style={styles.modalItem}>
                            <Input placeholder='項目名' value={name} onChange={(e) => setName(e.target.value)} />
                            <Input placeholder="値" value={value} onChange={(e) => setValue(e.target.value)} />
                            <Button onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                editButton();
                            }}>編集！</Button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ModalDialog;

const styles: { [key: string]: React.CSSProperties } = {
    modal: {
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 100,
        width: "100vw",
        height: "100vh",
        background: "rgba(0, 0, 0, 0.5)"
    },
    modalItem: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    }
}