import React, { useEffect } from 'react';
import { HeartOutlined } from '@ant-design/icons';
import './App.css';
import { Button } from 'antd';
import ModalDialog from './ModalDialog';

// const readUrl = "http://localhost:8081/read";
const readUrl = "https://minecraft.teststudio.xyz/read";



interface dataJson {
    data: [{
        name: string,
        value: string,
    }]
}

const Main: React.FC = () => {
    const [visible, setVisible] = React.useState(localStorage.getItem("agreed") ? true : false);
    const [noteData, setNoteData] = React.useState<dataJson | null>(null);

    useEffect(() => {
        (async () => {
            const res = await fetch(readUrl);
            const data = await res.json() as dataJson;
            setNoteData(data);
        })();

    }, [])

    const buttonHandler = (e: React.MouseEvent) => {
        setVisible(!visible);
        localStorage.setItem("agreed", visible ? "" : "true");
    }

    return (
        <div style={styles.container}>
            <div style={styles.rules}>
                <h2><HeartOutlined />{"ルール"}<HeartOutlined /></h2>
                <p>常識の範囲内で使用してください。</p>
                <p>このサーバーはマインクラフト統合版です。</p>
                <p>最大同時接続人数は現在<span style={{ color: "#BC8888", fontWeight: "bold" }}>20人</span>です。</p>
                <p>もっと増やしたい場合、管理人に伝えてください！</p>
                <p>上記の内容に同意していただけたら下のボタンを押すと</p>
                <p>接続方法が表示されます！</p>
            </div>
            <div style={{ marginBottom: "2vh" }}>
                <Button style={{ boxShadow: "1px 1px #DDDDDD", }} onClick={(e) => { buttonHandler(e) }}>{!visible ? "上記の内容を理解しました！" : "やっぱやめます！"}</Button>
            </div>
            <div style={{ ...styles.howto, display: visible ? "" : "none", marginBottom: "2vh" }}>
                <h2><HeartOutlined />{"つなぎかた"}<HeartOutlined /></h2>
                <p>スマホ版でもPC版でも同じです。</p>
                <p>Minecraftを開いたらプレイボタンを押して、右上のサーバーを押してください。</p>
                <p>画面左のサーバーリストを下の方にスクロールすると、「追加のサーバー」という項目があります。</p>
                <p>そうすると「外部サーバー編集」という画面が開くので入力していきます。</p>
                <p>以下のように設定してください。</p>
                <ul style={{ listStyle: "none", textAlign: "center", fontWeight: "bold", padding: "2vw", marginRight: "auto", marginLeft: "auto", overflow: "revert" }}>
                    <li style={{ boxShadow: "3px 3px #DDDDDD", border: "solid 1px #DDDDDD", borderRadius: "20px", listStyleType: "none", margin: "2vh 0", padding: "2vh 0" }}><p>サーバー名<br />↓<br />好きな名前をつけてください</p></li>
                    <li style={{ boxShadow: "3px 3px #DDDDDD", border: "solid 1px #DDDDDD", borderRadius: "20px", listStyleType: "none", margin: "2vh 0", padding: "2vh 0" }}>サーバーアドレス<br />↓<br />minecraft.teststudio.xyz</li>
                    <li style={{ boxShadow: "3px 3px #DDDDDD", border: "solid 1px #DDDDDD", borderRadius: "20px", listStyleType: "none", margin: "2vh 0", padding: "2vh 0" }}>ポート<br />↓<br />19132</li>
                </ul>
                <p>設定できたら焦る気持ちを抑えて「保存」を押しましょう。</p>
                <p>そうするとさっきのサーバー画面に戻ると思うので、自分がつけた名前のサーバーを選んで</p>
                <p>画面右側の「サーバーに接続」を押してください！</p>
                <h3>それでは、素敵なマイクラライフを！</h3>
            </div>
            <div style={{ ...styles.note, display: visible ? "" : "none" }}>
                <h2><HeartOutlined />{"便利データ集"}<HeartOutlined /></h2>
                <ul style={{ listStyle: "none", textAlign: "center", fontWeight: "bold", padding: "2vw", marginRight: "auto", marginLeft: "auto", overflow: "revert" }}>
                    {
                        noteData?.data.map((item, index) => {
                            return (
                                <ModalDialog key={item.name} name={item.name} value={item.value}>
                                    <li key={index} style={{ boxShadow: "3px 3px #AADDDD", border: "solid 1px #AADDDD", borderRadius: "20px", listStyleType: "none", margin: "2vh 0", padding: "2vh 0" }}>
                                        ☆{item.name}☆<br />{item.value}
                                    </li>
                                </ModalDialog>
                            )
                        })
                    }
                    <ModalDialog key={'add'} name={''} value={''}>
                        <li style={{ width: "50%", boxShadow: "3px 3px #AAAADD", border: "solid 1px #AAAADD", borderRadius: "20px", listStyleType: "none", margin: "2vh auto 2vh auto", padding: "2vh 0" }}>
                            ☆項目追加☆
                        </li>
                    </ModalDialog>
                </ul>
            </div>
            <p>管理者: かなこ</p>
        </div>
    );
};

export default Main;

const styles: { [key: string]: React.CSSProperties } = {
    containrt: {
        marginBottom: "100px"
    },
    rules: {
        padding: "5%",
        marginLeft: "auto",
        marginRight: "auto",
        width: "80%",
        border: "solid 2px #56DDDD",
        marginBottom: "20px",
        borderRadius: "20px",
    },
    howto: {
        padding: "5%",
        marginLeft: "auto",
        marginRight: "auto",
        width: "80%",
        border: "solid 2px #DD56DD",
        borderRadius: "20px",

    },
    note: {
        padding: "5%",
        marginLeft: "auto",
        marginRight: "auto",
        width: "80%",
        border: "solid 2px #AADDDD",
        borderRadius: "20px",

    }


}