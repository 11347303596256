import React, { useEffect } from 'react';
import './App.css';
import Main from './Main';


function App() {
  useEffect(() => {
    document.title = "隅っこマイクラサーバー(仮)"
  }
  , [])

  return (
    <div className="App">
      <h1>隅っこマイクラサーバー(仮)</h1>
      <Main/>
    </div>
  );
}

export default App;
